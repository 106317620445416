import {useNavigate} from 'react-router-dom'
import {createLink} from '../../utils/dataUtils'
import {useIntl} from 'react-intl'
import ActiveButton from '../../components/buttons/ActiveButton'
import QuickMenuRoot from './QuickMenuRoot'
import {EnumGoodsQuickMenuItem, TEnumGoodsQuickMenuItem} from './types'

interface GoodQuickMenuProps {
  activeItem: TEnumGoodsQuickMenuItem
  backPath?: string
}
const GoodsQuickMenu = (props: GoodQuickMenuProps) => {
  const intl = useIntl()
  const navigator = useNavigate()

  return (
    <QuickMenuRoot
      rightChildren={
        props.backPath?.length ? (
          <ActiveButton
            isActive={false}
            className='fs-7'
            onClick={() => navigator(`${props.backPath ?? ''}`)}
          >
            {intl.formatMessage({id: 'ACTION.BACK'})}
          </ActiveButton>
        ) : null
      }
    >
      <ActiveButton
        isActive={props.activeItem === EnumGoodsQuickMenuItem.List}
        className='me-5 fs-7'
        onClick={() => navigator('/goods/list')}
      >
        {intl.formatMessage({id: 'COMPONENTS.TABLES.GOODS.LIST.BUTTONS.GOODSLIST'})}
      </ActiveButton>

      <ActiveButton
        isActive={props.activeItem === EnumGoodsQuickMenuItem.NewItem}
        className='me-5 fs-7'
        onClick={() => navigator(createLink.toGood(null, true))}
      >
        {intl.formatMessage({id: 'COMPONENTS.TABLES.GOODS.LIST.BUTTONS.NEWGOOD'})}
      </ActiveButton>

      <ActiveButton
        isActive={props.activeItem === EnumGoodsQuickMenuItem.Import}
        className='me-5 fs-7'
        onClick={() => navigator('/goods/import/list')}
      >
        {intl.formatMessage({id: 'COMPONENTS.TABLES.GOODS.LIST.BUTTONS.IMPORTGOODS'})}
      </ActiveButton>

      <ActiveButton
        isActive={props.activeItem === EnumGoodsQuickMenuItem.MpCards}
        className='me-5 fs-7'
        onClick={() => navigator('/goods/mpcards/list')}
      >
        {intl.formatMessage({id: 'COMPONENTS.TABLES.GOODS.LIST.BUTTONS.MPCARDS'})}
      </ActiveButton>

      <ActiveButton
        isActive={props.activeItem === EnumGoodsQuickMenuItem.ComparisonCategory}
        className='me-5 fs-7'
        onClick={() => navigator('/goods/comparison/categoryeditor')}
      >
        {intl.formatMessage({id: 'COMPONENTS.TABLES.GOODS.LIST.BUTTONS.COMPARISONCATEGORY'})}
      </ActiveButton>

      <ActiveButton
        isActive={props.activeItem === EnumGoodsQuickMenuItem.ComparisonCards}
        className='me-5 fs-7'
        onClick={() => navigator('/goods/comparison/cards')}
      >
        {intl.formatMessage({id: 'COMPONENTS.TABLES.GOODS.LIST.BUTTONS.COMPARISONCARDS'})}
      </ActiveButton>

      <ActiveButton
        isActive={props.activeItem === EnumGoodsQuickMenuItem.Groups}
        className='fs-7'
        onClick={() => navigator('/goods/groups/list')}
      >
        {intl.formatMessage({id: 'COMPONENTS.TABLES.GOODS.LIST.BUTTONS.GROUPS'})}
      </ActiveButton>
    </QuickMenuRoot>
  )
}

export default GoodsQuickMenu
