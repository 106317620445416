import {createAction} from '@reduxjs/toolkit'
import {mpWizardActionsTypes, TCompany, TGetCaptchaRequest} from './types'
import {ITestFieldsToSend} from '../marketplaces/types'

export const GET_CAPTCHA = mpWizardActionsTypes.getCaptcha
export const POST_CAPTCHA = mpWizardActionsTypes.postCaptcha
export const POST_CODE_PHONE = mpWizardActionsTypes.postCodePhone
export const ADD_CUSTOMER_COMPANY = mpWizardActionsTypes.addCustomerCompany
export const ADD_ACCOUNT = mpWizardActionsTypes.addAccount
export const TEST_CONNECTION = mpWizardActionsTypes.testConnection
export const GET_YM_ACCOUNTS = mpWizardActionsTypes.getYMAccountsByToken

export const getCaptchaAction = createAction<TGetCaptchaRequest>(GET_CAPTCHA)
export const postCaptchaAction = createAction<string>(POST_CAPTCHA)
export const postCodePhoneAction = createAction<string>(POST_CODE_PHONE)
export const addCustomerCompanyAction =
  createAction<{company: TCompany; accId?: number}>(ADD_CUSTOMER_COMPANY)
export const addAccountAction = createAction<any>(ADD_ACCOUNT)
export const testConnectionAction = createAction<ITestFieldsToSend>(TEST_CONNECTION)
export const getYMAccountsByTokenAction = createAction<number>(GET_YM_ACCOUNTS)
