import {createAction} from '@reduxjs/toolkit'
import {
  EnumGoodsGroupsActionTypes,
  IGoodsGroupForSend,
  IGoodsGroupSendParams,
  IFilteredGoodsGroupRequest,
} from './types'

export const FETCH_GOODS_GROUPS = EnumGoodsGroupsActionTypes.FetchGoodsGroups

export const FETCH_GOODS_GROUP_BY_ID = EnumGoodsGroupsActionTypes.FetchGoodsGroupById

export const UPDATE_ASYNC_GOODS_GROUP_BY_ID = EnumGoodsGroupsActionTypes.UpdateGoodsGroupById

export const DELETE_ASYNC_GOODS_GROUP_BY_ID = EnumGoodsGroupsActionTypes.DeleteGoodsGroupById

export const fetchGoodsGroupsAction = createAction(FETCH_GOODS_GROUPS)

export const fetchGoodsGroupByIdAction = createAction<number>(FETCH_GOODS_GROUP_BY_ID)

export const updateAsyncGoodsGroupByIdAction = createAction<IGoodsGroupForSend>(
  UPDATE_ASYNC_GOODS_GROUP_BY_ID
)

export const deleteAsyncGoodsGroupByIdAction = createAction<number>(DELETE_ASYNC_GOODS_GROUP_BY_ID)
