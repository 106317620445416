import {IUrlParameters} from '../../components/lists/UrlFilteredList'
import {emptyUrlParameters} from '../../utils/constants'
import {TEnumMarketPlaceCode} from '../marketplaces/types'

export const emptyFeedbackUrlParameters: IUrlParameters = {
  ...emptyUrlParameters,
  url: '/feedback/answers/',
}

export type accaunt = {
  id: number
  name: string | null
  company?: number
}

export type generateAccaunt = {
  id: number
  name: string
  company?: number
}

export interface IMarketplace {
  id: number
  code: TEnumMarketPlaceCode
  color: string
}

type sku = {
  customer_uid: string
  nmid?: string
  name: string
}
type TVideo = {
  durationSec: number
  link: string
  previewImage: string
}

export interface IFeedback {
  id: number
  account: accaunt
  answer: string
  author: string
  created_date: string
  feedback_id: string
  feedback_status: string
  feedback_text: string
  images: string[]
  marketplace: IMarketplace
  order_id: string
  videos: TVideo[]
  name: string
  order_status: string
  rate: number
  sku: sku
  tags: IFeedbackTag[]
}

interface IFeedbackTag {
  id: number
  name: string
  code: string
  customer: number | null
  participating_in_mark_up: boolean
  participating_in_rules: boolean
  tonality: 'positive' | 'negative' | 'neutral'
  type: IFeedbackTagType
}

interface IFeedbackTagType {
  id: number
  name: string
  code: string
}

export interface IFeedbackResponse {
  count: number
  next: string | null
  previous: string | null
  results: IFeedback[]
}

export interface IFeedbackSendParams {
  text: string
  account: number
  feedback_code: string
  name: string | null
  feedback_id?: string | null
}
export interface IFeedbackRequestParams {
  good_name: string | null
  category: string | null
  rating: string
  text: string | null
  name: string | null
  code?: string | null
}

export const emptyFeedback: IFeedbackRequestParams = {
  good_name: null,
  category: '',
  rating: '',
  text: '',
  name: '',
}

export interface IFeedbackGenerateSettings {
  accounts: generateAccaunt[] | []
  customer: number | null
  rates_notifications: string[]
  new_feedbacks_notifications: boolean
}

export interface IFeedbackGeneratePostSettings extends Omit<IFeedbackGenerateSettings, 'accounts'> {
  accounts: number[] | []
}

export const emptyFeedbackGenerateSettings: IFeedbackGenerateSettings = {
  accounts: [],
  customer: 0,
  rates_notifications: ['1', '2', '3', '4', '5', 'all'],
  new_feedbacks_notifications: false,
}

export interface IFeedbackState {
  generatedFeedback: string
  generationTaskId: string
  isFeedbackLoading: boolean
  isFilteredItemsLoading: boolean
  isGenerated: boolean
  isLoadingSelect: boolean
  isSendLoading: boolean
  sendResult: boolean
  items: IFeedback[]
  selected: IFeedback | null
  count: number
  next: string | null
  previous: string | null
  filters: IUrlParameters
  isShowFeedbackSettingsModal: boolean
  feedbacksSettingsIsLoading: boolean
  feedbackGenerateSettings: IFeedbackGenerateSettings
}

export const emptyState: IFeedbackState = {
  generatedFeedback: '',
  generationTaskId: '',
  isFeedbackLoading: false,
  isFilteredItemsLoading: false,
  isGenerated: false,
  isLoadingSelect: false,
  isSendLoading: false,
  sendResult: false,
  items: [],
  selected: null,
  count: 0,
  next: null,
  previous: null,
  filters: emptyFeedbackUrlParameters,
  isShowFeedbackSettingsModal: false,
  feedbacksSettingsIsLoading: true,
  feedbackGenerateSettings: emptyFeedbackGenerateSettings,
}

export type TEnumFeedbacksActionTypes =
  | 'FetchFeedbacks'
  | 'FetchFeedbackById'
  | 'SET_Feedback'
  | 'SEND_Feedback'
  | 'FetchSettings'

export const EnumFetchFeedbacks = {
  FetchFeedbacks: 'FetchFeedbacks' as TEnumFeedbacksActionTypes,
  FetchFeedbackById: 'FetchFeedbackById' as TEnumFeedbacksActionTypes,
  SET_Feedback: 'SET_Feedback' as TEnumFeedbacksActionTypes,
  SEND_Feedback: 'SEND_Feedback' as TEnumFeedbacksActionTypes,
  FetchSettings: 'FetchSettings' as TEnumFeedbacksActionTypes,
}
