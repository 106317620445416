import {takeLatest, put, select} from 'redux-saga/effects'
import {PayloadAction} from '@reduxjs/toolkit'
import {
  setSteps,
  setMarketplace,
  updateData,
  setIsLoading,
  nextStep,
  setIsSuccessLastOperation,
} from './reducer'
import {
  GET_CAPTCHA,
  getCaptchaAction,
  POST_CAPTCHA,
  POST_CODE_PHONE,
  postCaptchaAction,
  postCodePhoneAction,
  ADD_CUSTOMER_COMPANY,
  ADD_ACCOUNT,
  testConnectionAction,
  TEST_CONNECTION,
  GET_YM_ACCOUNTS,
} from './actions'
import {updateAsyncUserCreator} from '../users/actions'
import {getAsyncCustomerCompanysCreator} from '../customerCompanys/actions'
import {getCaptcha, postCaptcha, postCodePhone, getYMAccountsByToken} from './api'
import {addCustomerCompanyToServer} from '../customerCompanys/api'
import {testAccountConnection} from '../accounts/api'
import {addAccountToServer} from '../accounts/api'
import {sagaUtils} from '../../utils/dataUtils'
import {steps} from '../../modules/mpWizard/utils/constants'

import {TCompany, TMarketplace, TYMAccount, IWizardState, EnumStatuses} from './types'
import {EnumMarketPlaceCode} from '../marketplaces/types'
import {StorageType} from '../../../setup'
import {IAccount} from '../accounts/types'
import {IUserModel} from '../../modules/auth/models/UserModel'

export function* mpWizardSaga() {
  yield takeLatest(setMarketplace.type, workerSetSteps)
  yield takeLatest(GET_CAPTCHA, workerGetCaptcha)
  yield takeLatest(POST_CAPTCHA, workerPostCaptcha)
  yield takeLatest(POST_CODE_PHONE, workerPostCodePhone)
  yield takeLatest(ADD_CUSTOMER_COMPANY, workerAddCustomerCompany)
  yield takeLatest(ADD_ACCOUNT, workerAddAccount)
  yield takeLatest(TEST_CONNECTION, workerTestConnection)
  yield takeLatest(GET_YM_ACCOUNTS, workerGetYMAccounts)
}

function* workerSetSteps(action: ReturnType<typeof setMarketplace>) {
  const marketplace = action.payload

  yield put(setSteps(steps[marketplace.code]))
}

export function* workerGetCaptcha(action: ReturnType<typeof getCaptchaAction>) {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    getCaptcha,
    action.payload,
    updateData,
    null,
    'Получение каптчи',
    'GET',
    'data',
    false,
    '',
    undefined,
    {
      action: updateData,
      params: {key: 'step1', field: 'captchaStatus', value: EnumStatuses.WAITING},
    },
    undefined,
    undefined,
    undefined,
    (res: Blob) => {
      if (res.type === 'application/json' && !action.payload.isRegeneration) {
        return {key: 'step1', field: 'captchaStatus', value: EnumStatuses.SUCCESS}
      }

      return {key: 'step1', field: 'captcha', value: window.URL.createObjectURL(res)}
    }
  )
}

export function* workerPostCaptcha(action: ReturnType<typeof postCaptchaAction>) {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    postCaptcha,
    action.payload,
    updateData,
    null,
    'Отправка каптчи',
    'PUBLISH',
    'data',
    false,
    '',
    {
      action: updateData,
      params: {key: 'step1', field: 'captchaStatus', value: EnumStatuses.WAITING},
    },
    undefined,
    undefined,
    undefined,
    undefined,
    (res: any) => {
      return {
        key: 'step1',
        field: 'captchaStatus',
        value: res.success ? EnumStatuses.SUCCESS : EnumStatuses.ERROR,
      }
    }
  )
}
export function* workerTestConnection(action: ReturnType<typeof testConnectionAction>) {
  const currentStep: number = yield select((s: StorageType) => s.mpWizard.currentStep)
  const marketplace: TMarketplace = yield select((s: StorageType) => s.mpWizard.marketplace)

  yield sagaUtils.workerWrapper(
    setIsLoading,
    testAccountConnection,
    action.payload,
    updateData,
    null,
    'Проверка соединения',
    'PUBLISH',
    'data',
    false,
    '',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    (res: any) => {
      if (marketplace.code === EnumMarketPlaceCode.WB) {
        return {
          key: `step${currentStep}`,
          field: 'accessRights',
          value: res.results,
        }
      }

      return {
        key: `step${currentStep}`,
        field: 'status',
        value: res.success ? EnumStatuses.SUCCESS : EnumStatuses.ERROR,
      }
    }
  )
}

export function* workerPostCodePhone(action: ReturnType<typeof postCodePhoneAction>) {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    postCodePhone,
    action.payload,
    updateData,
    null,
    'Отправка кода',
    'PUBLISH',
    'data.results',
    false,
    '',
    undefined,
    {action: nextStep},
    undefined,
    undefined,
    undefined,
    (res: TCompany[]) => {
      return {key: 'step1', field: 'wbCompanies', value: res}
    }
  )
}

export function* workerAddCustomerCompany(action: any) {
  const {currentStep, marketplace}: IWizardState = yield select((s: StorageType) => s.mpWizard)

  yield sagaUtils.workerWrapper(
    setIsLoading,
    addCustomerCompanyToServer,
    action.payload.company,
    updateData,
    null,
    'Создание юр. лица',
    'PUBLISH',
    'data',
    true,
    'Можете перейти к следующему шагу',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    (res: TCompany) => {
      if (marketplace?.code === EnumMarketPlaceCode.YM) {
        return {
          key: `step${currentStep}`,
          field: `${action.payload.accId}`,
          value: {value: res, action: 'create'},
        }
      }

      return {
        key: `step${currentStep}`,
        field: 'customerCompany',
        value: {value: res, action: 'create'},
      }
    }
  )

  yield put(getAsyncCustomerCompanysCreator())
}
export function* workerAddAccount(action: PayloadAction<any>) {
  const marketplace: TMarketplace = yield select((s: StorageType) => s.mpWizard.marketplace)

  let submitData: IAccount[] = []

  switch (marketplace.code) {
    case EnumMarketPlaceCode.WB:
      submitData.push({
        marketplace: marketplace.id,
        company: action.payload?.step1.customerCompany.value?.id || 0,
        id: 0,
        name: action.payload?.step2?.accountName || '',
        token_access_rights: action.payload?.step0?.accessRights || [],
        params: {
          authorization: action.payload?.step0?.authorization || '',
        },
      })
      break

    case EnumMarketPlaceCode.YM: {
      const selectedYMAccounts: TYMAccount[] = action.payload?.step1?.selectedYMAccounts

      const companysInfo = action.payload?.step2
      const accNames = action.payload?.step3

      selectedYMAccounts.forEach((acc) => {
        submitData.push({
          marketplace: marketplace.id,
          company: companysInfo[acc.id].value.id,
          id: 0,
          name: accNames[acc.id],
          params: {business_id: acc.business.id.toString(), campaign_id: acc.id.toString()},
        })
      })
      break
    }
    case EnumMarketPlaceCode.OZON:
      submitData.push({
        marketplace: marketplace.id,
        company: action.payload?.step3?.customerCompany.value.id || 0,
        id: 0,
        name: action.payload?.step4?.accountName || '',
        params: {
          api_id: action.payload?.step0?.apiId || '',
          api_key: action.payload?.step0?.apiKey || '',
          perfomance_api_key: action.payload?.step1?.clientId || '',
          perfomance_api_secret: action.payload?.step1?.apiSecret || '',
        },
      })
      break
    default:
      break
  }

  if (action.payload?.step2?.ozonCookie) {
    const user: IUserModel = yield select((s: StorageType) => s.auth.user)

    yield put(
      updateAsyncUserCreator({
        ...user,
        params: {
          ...user?.params,
          ozon_auth: {cookie: action.payload?.step2?.ozonCookie},
        },
      })
    )
  }

  yield sagaUtils.workerWrapper(
    setIsLoading,
    addAccountToServer,
    submitData,
    setIsSuccessLastOperation,
    null,
    'Создание аккаунта',
    'ADD',
    'data',
    true
  )
}

export function* workerGetYMAccounts(action: any) {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    getYMAccountsByToken,
    action.payload,
    updateData,
    null,
    'Отправка кода',
    'GET',
    'data.results',
    false,
    '',
    undefined,
    {action: nextStep},
    undefined,
    undefined,
    undefined,
    (res: any) => {
      return {key: 'step0', field: 'ymAccounts', value: res}
    }
  )
}
