import {createSlice, createSelector} from '@reduxjs/toolkit'
import {initialMarketplaces as initialState, IMarketplace} from './types'
import {StorageType} from '../../../setup'
import {MP_CODES_LIST} from '../../utils/constants'

const marketplaceSlice = createSlice({
  name: 'marketplaceSlice',
  initialState,
  reducers: {
    setMarketplaces(state, action) {
      state.items = [...action.payload]
    },
  },
})

export const usedMarketplacesSelector = createSelector(
  (state: StorageType) => state.marketplaces.items,
  (marketplaces: IMarketplace[]) => {
    return marketplaces.filter((mp) => MP_CODES_LIST.some((item) => item.code === mp.code))
  }
)

export const marketplaceReducer = marketplaceSlice.reducer
export const {setMarketplaces} = marketplaceSlice.actions

// const reducer = createReducer(initialAccount, (builder) => {
//     builder
//         .addCase(GET_ACCOUNTS, (s, a): PayloadAction<IAccount> => {
//             return {...s,
//                 accounts: a.payload
//             }
//         })
// })

//export default accountSlice.reducer
