import {useState, ChangeEvent} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  useGetCodePhoneFromOzonMutation,
  usePostCodePhoneFromOzonMutation,
  usePostEmailCodeFromOzonMutation,
} from '../../../../../store/mpWizard/api'
import {nextStep, setSelectedToken} from '../../../../../store'

import {Button} from 'react-bootstrap'
import PhoneInput from './PhoneInput'
import CodeInput from './CodeInput'
import TokenSelect from '../../TokenSelect'

import {StorageType} from '../../../../../../setup'
import {IUserModel} from '../../../../auth/models/UserModel'
import {IMarketplace} from '../../../../../store/marketplaces/types'
import {ITokenFilters} from '../../../../../store/tokens/types'
import {IOption} from '../../../../../utils/types'

interface TStepData {
  phone: string
  phoneCode: string
  emailCode: string
}

type TCoockiesStep = 'phone' | 'phoneCode' | 'emailCode'

const OzonStep3 = () => {
  const [stepData, setStepData] = useState<TStepData>({
    phone: '',
    phoneCode: '',
    emailCode: '',
  })

  const [cookiesStep, setCookiesStep] = useState<TCoockiesStep>('phone')

  const currentMarketplace: IMarketplace = useSelector(
    (storage: StorageType) => storage.mpWizard.marketplace
  )

  const selectedToken: IOption | null = useSelector(
    (storage: StorageType) => storage.mpWizard.selectedToken
  )

  const user: IUserModel = useSelector((storage: StorageType) => storage.auth.user)

  const tokenFilters: ITokenFilters = {
    customer: user.customer.id.toString(),
    marketplace: [{label: '', value: currentMarketplace.id.toString()}],
  }

  const [
    getCodePhoneFromOzon,
    {isLoading: isLoadingGetPhoneCode, data: getCodePhoneFromOzonResponse},
  ] = useGetCodePhoneFromOzonMutation()

  const [postCodePhoneFromOzon, {isLoading: isPostingPhoneCode}] =
    usePostCodePhoneFromOzonMutation()

  const [postEmailCodeFromOzon, {isLoading: isPostingEmailCode}] =
    usePostEmailCodeFromOzonMutation()

  const dispatch = useDispatch()

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>, inputValue: string = '') => {
    const {value, name} = e.target

    if (name === 'phone') {
      setStepData((prev) => ({...prev, [name]: inputValue}))
      return
    }

    setStepData((prev) => ({...prev, [name]: value}))
  }

  const onNextStep = () => {
    dispatch(nextStep())
  }

  const onSendPhone = async (phone: string) => {
    if (!phone) {
      return
    }

    try {
      const response = await getCodePhoneFromOzon({
        phone_number: phone,
        sd_id: user.id,
      }).unwrap()

      const tokenId = response.result

      if (tokenId) {
        dispatch(setSelectedToken({label: '', value: tokenId.toString()}))
      }

      setCookiesStep('phoneCode')
    } catch (error) {}
  }

  const onSendCodePhone = async (phone: string, phoneCode: string) => {
    if (!phoneCode) {
      return
    }

    setStepData((prev) => ({...prev, phoneCode: ''}))

    try {
      await postCodePhoneFromOzon({
        phone_number: phone,
        code: phoneCode,
      }).unwrap()

      setCookiesStep('emailCode')
    } catch (error) {}
  }

  const onSendEmailCode = async (emailCode: string, phone: string) => {
    if (!emailCode) {
      return
    }

    setStepData((prev) => ({...prev, emailCode: ''}))

    try {
      await postEmailCodeFromOzon({
        phone_number: phone,
        code: emailCode,
      }).unwrap()

      onNextStep()
    } catch (error) {}
  }

  const onConfirm = (action: TCoockiesStep, stepData: TStepData) => () => {
    const {phone, phoneCode, emailCode} = stepData

    const transformedPhone = phone.slice(1)

    if (action === 'phone') {
      onSendPhone(transformedPhone)
    } else if (action === 'emailCode') {
      onSendEmailCode(emailCode, transformedPhone)
    } else if (action === 'phoneCode') {
      onSendCodePhone(transformedPhone, phoneCode)
    } else {
      return
    }
  }

  const onChangeNumber = () => {
    setCookiesStep('phone')

    setStepData({
      phone: '',
      phoneCode: '',
      emailCode: '',
    })

    dispatch(setSelectedToken(null))
  }

  return (
    <div>
      <p className='mb-8 fs-5 text-gray-700'>
        Пожалуйста, введите номер телефона, привязанный к вашему личному кабинету{' '}
        <span className='fw-bold'>Ozon</span>. Это необязательный шаг, но без него не будет
        возможности загрузить отзывы с Ozon в наш сервис.
      </p>

      {cookiesStep === 'phone' && (
        <PhoneInput
          onChangeInput={onChangeInput}
          disabled={isLoadingGetPhoneCode || Boolean(selectedToken)}
        />
      )}

      {cookiesStep === 'phoneCode' && (
        <CodeInput
          value={stepData.phoneCode}
          onChangeInput={onChangeInput}
          name='phoneCode'
          disabled={isPostingPhoneCode}
          placeholder='Введите последние 6 цифр входящего звонка'
        />
      )}

      {cookiesStep === 'emailCode' && (
        <CodeInput
          value={stepData.emailCode}
          onChangeInput={onChangeInput}
          name='emailCode'
          disabled={isPostingEmailCode}
          placeholder='Введите код из электронной почты'
        />
      )}

      <div className='d-flex gap-3 mb-5'>
        <Button
          disabled={
            isLoadingGetPhoneCode ||
            isPostingPhoneCode ||
            isPostingEmailCode ||
            !Boolean(stepData[cookiesStep])
          }
          onClick={onConfirm(cookiesStep, stepData)}
        >
          Подтвердить
        </Button>
        {cookiesStep === 'phoneCode' && (
          <Button variant='secondary' onClick={onChangeNumber} disabled={isPostingPhoneCode}>
            Изменить номер
          </Button>
        )}
      </div>
      {cookiesStep === 'phone' && (
        <>
          <p className='fs-5 text-gray-700'>Или выберите токен из списка ниже</p>
          <TokenSelect className='w-25' filters={tokenFilters} disabled={Boolean(stepData.phone)} />
        </>
      )}

      {selectedToken && cookiesStep !== 'phoneCode' && cookiesStep !== 'emailCode' && (
        <Button className='mt-5' onClick={onNextStep}>
          Далее
        </Button>
      )}
    </div>
  )
}

export default OzonStep3
