import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate, Outlet} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {LayoutForWarehouse} from '../../_metronic/layout/LayoutForWarehouse'
import MpDocsPage from '../modules/mpdocs/MpDocsPage'
import {MPDOCS} from './publicRoutes'

const PrivateRoutes = () => {
  // TODO: сделать компактнее
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const GoodsPage = lazy(() => import('../modules/goods/GoodsPage'))
  const OrdersPage = lazy(() => import('../modules/orders/OrdersPage'))
  const PricesPage = lazy(() => import('../modules/prices/PricesPage'))
  const MarketingPage = lazy(() => import('../modules/marketing/MarketingPage'))
  const EnumTransactionStatus = lazy(
    () => import('../modules/transactionStatus/TransactionStatusPage')
  )
  const HomePage = lazy(() => import('../modules/home/HomePage'))
  const LogisticsPage = lazy(() => import('../modules/logistics/LogisticsPage'))
  const ServicesPage = lazy(() => import('../modules/services/ServicesPage'))
  const MPWizardPage = lazy(() => import('../modules/mpWizard/MPWizardPage'))
  const SalesManagmentPage = lazy(() => import('../modules/salesManagement/SalesManagmentPage'))
  const FeedbackPage = lazy(() => import('../modules/feedback/FeedbackPage'))
  const AcceptanceRatesPage = lazy(() => import('../modules/acceptanceRates/AcceptanceRatesPage'))
  const MpAccountsPage = lazy(() => import('../modules/mpAccounts/MpAccountsPage'))

  return (
    <Routes>
      <Route element={<LayoutForWarehouse />}>
        <Route path={`${MPDOCS}/*`} element={<MpDocsPage rootPath={MPDOCS} />} />
      </Route>

      <Route element={<MasterLayout />}>
        {/* Redirect to Home Page after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/home' />} />

        {/* Pages */}
        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        <Route
          path='home'
          element={
            <SuspensedView>
              <HomePage />
            </SuspensedView>
          }
        />

        <Route
          path='transaction/status/:transactionId'
          element={
            <SuspensedView>
              <EnumTransactionStatus />
            </SuspensedView>
          }
        />

        <Route
          path='goods/*'
          element={
            <SuspensedView>
              <GoodsPage />
            </SuspensedView>
          }
        />

        <Route
          path='logistics/*'
          element={
            <SuspensedView>
              <LogisticsPage />
            </SuspensedView>
          }
        />

        <Route
          path='services/*'
          element={
            <SuspensedView>
              <ServicesPage />
            </SuspensedView>
          }
        />
        <Route
          path='feedback/*'
          element={
            <SuspensedView>
              <FeedbackPage />
            </SuspensedView>
          }
        />

        <Route
          path='marketplaces-wizard/*'
          element={
            <SuspensedView>
              <MPWizardPage />
            </SuspensedView>
          }
        />

        <Route
          path='acceptance-rates/*'
          element={
            <SuspensedView>
              <AcceptanceRatesPage />
            </SuspensedView>
          }
        />

        <Route
          path='mp-accounts'
          element={
            <SuspensedView>
              <MpAccountsPage />
            </SuspensedView>
          }
        />

        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='marketing' element={<Outlet />}>
          <Route path='companies' element={<MarketingPage />} />
          <Route index element={<Navigate to='/marketing/companies' />} />
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Pages */}
        <Route
          path='prices/*'
          element={
            <SuspensedView>
              <PricesPage />
            </SuspensedView>
          }
        />
        <Route
          path='orders/*'
          element={
            <SuspensedView>
              <OrdersPage />
            </SuspensedView>
          }
        />
        <Route
          path='sales-management'
          element={
            <SuspensedView>
              <SalesManagmentPage />
            </SuspensedView>
          }
        />
      </Route>

      {/* <Route
            path='mpdocs/*'
            element={
              <SuspensedView>
                <MpDocsPage />
              </SuspensedView>
            }
          /> */}
      {/* Page Not Found */}
      <Route path='*' element={<Navigate to='/error/404' />} />
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
