interface ICheckTransactionStatusState {
  status: TEnumTransactionStatus
}

export type TEnumTransactionStatus = 'pending' | 'paid' | 'canceled' | 'error'

export const EnumTransactionStatus = {
  PENDING: 'pending' as TEnumTransactionStatus,
  CANCELED: 'canceled' as TEnumTransactionStatus,
  PAID: 'paid' as TEnumTransactionStatus,
  ERROR: 'error' as TEnumTransactionStatus,
}

export const initState: ICheckTransactionStatusState = {
  status: EnumTransactionStatus.PENDING,
}

export interface ICheckTransactionStatusResponse {
  id: number
  customer: number
  amount: string
  issued_date: string
  due_date: string
  status: TEnumTransactionStatus
}

export const transactionsActionTypes = {
  CHECK_TRANSACTION_STATUS: '[CheckTransactionStatus] Action',
  STOP_CHECKING_TRANSACTION_STATUS: '[StopCheckingTransactionStatus] Action',
}
