import {FC, ChangeEvent} from 'react'
import {default as PhoneInputComponent} from 'react-phone-input-2'

interface IPhoneInputProps {
  onChangeInput: (event: ChangeEvent<HTMLInputElement>, inputValue: string) => void
  disabled: boolean
}

const PhoneInput: FC<IPhoneInputProps> = ({onChangeInput, disabled}) => {
  return (
    <div>
      <PhoneInputComponent
        inputProps={{
          name: 'phone',
        }}
        containerClass='mb-6'
        country='ru'
        disabled={disabled}
        inputClass='form-control form-control-lg form-control-solid'
        onChange={(value, data, event) => onChangeInput(event, value)}
        regions={['ex-ussr']}
      />
    </div>
  )
}

export default PhoneInput
