import {IUrlParameters} from '../../components/lists/UrlFilteredList'
import {emptyUrlParameters} from '../../utils/constants'

export const emptyTransactionUrlParameters: IUrlParameters = {
  ...emptyUrlParameters,
  url: '/account/settings/transactions/',
}

export type TransactionType = {
  id: number
  amount: string
  date: string
  transaction_type: string
  description: string
  customer: number
}

export interface ITransaction {
  count: number
  next: string | null
  previous: string | null
  results: TransactionType[]
}

export interface ITransactionState {
  transactions: ITransaction
  isLoading: boolean
}

export interface ItransactionsStorage {
  isLoading: boolean
  items: TransactionType[]
  selected: TransactionType | null
  count: number
  next: string | null
  previous: string | null
  filters: IUrlParameters
}

export const emptyState: ItransactionsStorage = {
  isLoading: false,
  items: [],
  selected: null,
  count: 0,
  next: null,
  previous: null,
  filters: emptyTransactionUrlParameters,
}

export type TEnumTransactionsActionTypes = 'FetchTransactions'

export const EnumTransactionsActionTypes = {
  FetchTransactions: 'FetchTransactions' as TEnumTransactionsActionTypes,
}
