import {apiSlice} from '../../../../setup/api/apiSlice'
import {IToken, ITokenType, IAddTokenRequest, ITokenFilters} from '../types'

import {IApiResponseWithResult, ITransformedResponse} from '../../../../setup/api/apiSlice'

const TOKENS_COMMON_SUFFIX = 'v2/tokens/'

const tokensApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTokens: builder.query<IToken[], ITokenFilters>({
      query: (params) => ({
        url: TOKENS_COMMON_SUFFIX,
        params: {
          token_type: params?.token_type?.value ?? '',
          marketplace: params?.marketplace?.map((item) => item.value) ?? [],
          customer: params?.customer ?? '',
        },
      }),
      providesTags: ['Tokens'],
      transformResponse: (response: IApiResponseWithResult<IToken>) => response.results as IToken[],
    }),
    addToken: builder.mutation<ITransformedResponse<IToken>, IAddTokenRequest>({
      query: (body) => ({
        url: TOKENS_COMMON_SUFFIX,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Tokens'],
      transformResponse: (response: IToken) => ({
        data: response,
        meta: {
          successMessage: 'Токен успешно добавлен',
        },
      }),
    }),
    deleteToken: builder.mutation<ITransformedResponse<IToken>, number>({
      query: (id) => ({
        url: `${TOKENS_COMMON_SUFFIX}${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Tokens'],
      transformResponse: (response: IToken) => ({
        data: response,
        meta: {
          successMessage: 'Токен успешно удален',
        },
      }),
    }),
    getTokenTypes: builder.query<ITokenType[], void>({
      query: () => `${TOKENS_COMMON_SUFFIX}get_token_types/`,
      transformResponse: (response: IApiResponseWithResult<ITokenType>) =>
        response.results as ITokenType[],
    }),
    linkTokenToAccounts: builder.mutation<void, {token: number; accounts: number[]}>({
      query: (body) => ({
        url: `${TOKENS_COMMON_SUFFIX}link_token_to_accounts/`,
        method: 'POST',
        body,
      }),
    }),
  }),
  overrideExisting: true,
})

export const {
  useLinkTokenToAccountsMutation,
  useGetTokensQuery,
  useAddTokenMutation,
  useDeleteTokenMutation,
  useLazyGetTokenTypesQuery,
} = tokensApiSlice
