import {createAction} from '@reduxjs/toolkit'
import {
  EnumInvoicesActionTypes,
  IPaymentRequestParams,
  IPaymentRequestOptionalParams,
  IPaymentPrintRequestParams,
} from './types'

export const FETCH_INVOICES = EnumInvoicesActionTypes.FetchInvoices
export const CREATE_PAYMENT_DOCUMENT = EnumInvoicesActionTypes.CreatePaymentDocument
export const PRINT_PAYMENT_DOCUMENT = EnumInvoicesActionTypes.PrintPaymentDocument
export const CREATE_PAYMENT_OPTIONAL_DOCUMENT =
  EnumInvoicesActionTypes.CreatePaymentOptionalDocument

export const fetchInvoicesAction = createAction(FETCH_INVOICES)
export const createPaymentDocumentAction =
  createAction<IPaymentRequestParams>(CREATE_PAYMENT_DOCUMENT)
export const createPaymentDocumentOptionalAction =
  createAction<IPaymentRequestOptionalParams>(CREATE_PAYMENT_DOCUMENT)
export const printPaymentDocumentAction =
  createAction<IPaymentPrintRequestParams>(PRINT_PAYMENT_DOCUMENT)
