import {useState, ChangeEvent} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  getCaptchaAction,
  postCaptchaAction,
  postCodePhoneAction,
} from '../../../../../store/mpWizard/actions'

import PhoneInput from 'react-phone-input-2'
import {Button, Form} from 'react-bootstrap'
import {UpdateIcon} from '../../../../../components/icons/Update'

import {StorageType} from '../../../../../../setup'
import {IWizardState, EnumStatuses} from '../../../../../store/mpWizard/types'

interface TStepData {
  phone: string
  captchaCode: string
  phoneCode: string
}

type TAction = 'getCaptcha' | 'postCaptcha' | 'postCodePhone'

// TODO: вынести в отдельные компоненты вводы кода и каптчи
const WBStep2 = () => {
  const [stepData, setStepData] = useState<TStepData>({
    phone: '',
    captchaCode: '',
    phoneCode: '',
  })

  const {isLoading, data}: IWizardState = useSelector((storage: StorageType) => storage.mpWizard)

  const captcha = data['step1']?.captcha
  const captchaStatus = data['step1']?.captchaStatus

  const dispatch = useDispatch()

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>, inputValue: string = '') => {
    const {value, name} = e.target

    if (name === 'phone') {
      setStepData((prev) => ({...prev, [name]: inputValue}))
      return
    }

    setStepData((prev) => ({...prev, [name]: value}))
  }

  const onClick = (action: TAction) => () => {
    if (action === 'getCaptcha') {
      dispatch(getCaptchaAction({phone: stepData.phone, isRegeneration: false}))
    } else if (action === 'postCaptcha') {
      dispatch(postCaptchaAction(stepData.captchaCode))
    } else if (action === 'postCodePhone') {
      dispatch(postCodePhoneAction(stepData.phoneCode))
    }
  }

  const onRegenerateCaptcha = () => {
    dispatch(getCaptchaAction({phone: stepData.phone, isRegeneration: true}))
  }

  const getAction = (): TAction => {
    if (
      captcha &&
      (captchaStatus === EnumStatuses.ERROR || captchaStatus === EnumStatuses.WAITING)
    ) {
      return 'postCaptcha'
    } else if (captchaStatus === EnumStatuses.SUCCESS) {
      return 'postCodePhone'
    } else {
      return 'getCaptcha'
    }
  }

  return (
    <div>
      <p className='fs-5 mb-9'>
        Для продолжения авторизации, пожалуйста, введите номер телефона, привязанный к вашему
        личному кабинету <span className='fw-bold'>Wildberries</span>. Это необходимо для
        подтверждения вашей личности и безопасного доступа к вашему аккаунту через наш сервис.
      </p>
      <PhoneInput
        inputProps={{
          name: 'phone',
        }}
        disabled={captcha || captchaStatus}
        containerClass='mb-8'
        country='ru'
        inputClass='form-control form-control-lg form-control-solid'
        onChange={(value, data, event) => onChangeInput(event, value)}
        regions={['ex-ussr']}
      />

      {captcha && (captchaStatus === EnumStatuses.ERROR || captchaStatus === EnumStatuses.WAITING) && (
        <div>
          <p className='fs-5'>Введите код с картинки</p>
          <div className='d-flex align-items-end gap-3 mb-5'>
            <img src={captcha} alt='captcha' />
            <div className='cursor-pointer' onClick={onRegenerateCaptcha}>
              <UpdateIcon />
            </div>
          </div>
          <Form.Control
            value={stepData.captchaCode}
            name='captchaCode'
            className='form-control form-control-lg form-control-solid w-25 mb-5'
            onChange={onChangeInput}
          />
          {captchaStatus === EnumStatuses.ERROR && (
            <p className='text-danger'>Каптча не прошла проверку</p>
          )}
        </div>
      )}

      {captchaStatus === EnumStatuses.SUCCESS && (
        <div>
          <p className='fs-5'>
            Пожалуйста, введите код из SMS, который был отправлен на ваш номер телефона.
          </p>
          <Form.Control
            value={stepData.phoneCode}
            name='phoneCode'
            placeholder='Код из SMS'
            className='form-control form-control-lg form-control-solid w-25 mb-5'
            onChange={onChangeInput}
          />
        </div>
      )}

      <Button
        className='fs-6'
        onClick={onClick(getAction())}
        disabled={stepData.phone.length < 11 || isLoading}
      >
        Подтвердить
      </Button>
    </div>
  )
}

export default WBStep2
