import {createListenerMiddleware} from '@reduxjs/toolkit'
import {toast} from 'react-toastify'
import {errorUtils} from '../../../app/utils/dataUtils'
import {intl} from '../../../app/modules/goods/item/validation'
import {ITransformedResponse} from '../../api/apiSlice'

export const toastMiddleware = createListenerMiddleware()

toastMiddleware.startListening({
  predicate: (action) => action.type.endsWith('/fulfilled'),
  effect: (action, listenerApi) => {
    const succesMessage = (action.payload as ITransformedResponse<any>)?.meta?.successMessage

    if (succesMessage) {
      toast.success(succesMessage)
    }
  },
})

toastMiddleware.startListening({
  predicate: (action) => action.type.endsWith('/rejected'),
  effect: (action, listenerApi) => {
    const errorDetails = errorUtils.getErrorFromResponse(action.payload)

    const errorMessage = errorDetails
      .map((d: any) => {
        let message = ''

        if (typeof d === 'string') {
          return d
        }

        if (d !== null && typeof d === 'object') {
          Object.keys(d).forEach((key) => {
            if (d[key] && typeof d[key] === 'string') {
              message += `${key}: ${d[key]} `
            }
          })
        }

        return message
      })
      .join(', ')

    if (errorMessage.length > 0) {
      toast.error(errorMessage)
    } else {
      toast.error(intl.formatMessage({id: 'UTILS.ERRORS.UNKNOWNERROR'}))
    }
  },
})
