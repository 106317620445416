import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {IApiResponseWithResult} from '../../../setup/api/apiSlice'
import {IConnectedAccount} from '../answerSettings/types/replyRulesTypes'
import {IOption} from '../../utils/types'
import {initialState, TMarketplace, TStep, IStepData} from './types'

const mpWizardSlice = createSlice({
  name: 'mpWizardSlice',
  initialState,
  reducers: {
    setMarketplace: (state, action: PayloadAction<TMarketplace>) => {
      state.marketplace = action.payload
      state.data = {}
      state.steps = []
      state.validations = {}
      state.currentStep = 0
      state.stepHistory = []
      state.isSuccessLastOperation = false
      state.isLoading = false
      state.selectedToken = null
    },
    setSteps: (state, action: PayloadAction<TStep[]>) => {
      state.steps = action.payload
      state.stepHistory = []
    },
    setFrom(state, action) {
      state.from = action.payload
    },
    nextStep: (state) => {
      state.stepHistory.push(state.currentStep)
      state.currentStep = state.currentStep + 1
    },
    prevStep: (state) => {
      state.currentStep = state.stepHistory.pop() as number
    },
    skipStep: (state) => {
      const currentStepConfig = state.steps[state.currentStep]

      if (currentStepConfig.canSkip && currentStepConfig.skipCount) {
        let targetStep = state.currentStep + currentStepConfig.skipCount

        // Ensure the target step is within bounds
        if (targetStep >= state.steps.length) {
          targetStep = state.steps.length - 1
        }

        state.stepHistory.push(state.currentStep)
        state.currentStep = targetStep
      }
    },
    updateData: (state, action: PayloadAction<IStepData>) => {
      const {key, field, value} = action.payload

      if (!state.data[key]) {
        state.data[key] = {}
      }
      state.data[key][field] = value

      // Update validation
      if (!state.validations[key]) {
        state.validations[key] = {}
      }

      state.validations[key][field] = Boolean(value)
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setIsSuccessLastOperation: (
      state,
      action: PayloadAction<IApiResponseWithResult<IConnectedAccount>>
    ) => {
      state.isSuccessLastOperation = action.payload.success
      state.connectedAccounts = action.payload.results as IConnectedAccount[]
    },
    setSelectedToken: (state, action: PayloadAction<IOption | null>) => {
      state.selectedToken = action.payload
    },
    updateValidation: (state, action: PayloadAction<IStepData>) => {
      const {key, field, value} = action.payload

      state.validations[key][field] = Boolean(value)
    },
  },
})

const persistConfig = {
  key: 'mpWizard',
  storage,
  version: 1,
  blacklist: ['from'],
}

export const mpWizardReducer = persistReducer(persistConfig, mpWizardSlice.reducer)

export const {
  updateValidation,
  setSteps,
  setMarketplace,
  setSelectedToken,
  nextStep,
  prevStep,
  updateData,
  setIsLoading,
  skipStep,
  setFrom,
  setIsSuccessLastOperation,
} = mpWizardSlice.actions
