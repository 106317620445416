import axios from 'axios'
import {apiSlice} from '../../../setup/api/apiSlice'
import {
  TRegisterYMTokenRequest,
  TGetCaptchaRequest,
  IGetOzonCodeRequest,
  IPostOzonCodeRequest,
  IRegisterYMTokenResponse,
} from './types'

import {IApiResponseWithResult} from '../../../setup/api/apiSlice'

const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`
// const API_URL_OZON_COOKIE = 'http://10.44.10.132:8015/api/'
const TOKENS_PREFIX = 'v2/tokens/'
export const USERS_URL = `${API_URL}v2/users/`
export const TOKENS_URL = `${API_URL}${TOKENS_PREFIX}`

// #region
//https://api-dev.shop-delivery.ru/int_api/v2/users/get_captcha/

export const getCaptcha = (data: TGetCaptchaRequest) => {
  return axios.post(`${USERS_URL}get_captcha/`, {phone_number: data.phone}, {responseType: 'blob'})
}

// #endregion

// #region
//https://api-dev.shop-delivery.ru/int_api/v2/users/post_captcha/

export const postCaptcha = (captcha: string) => {
  return axios.post(`${USERS_URL}post_captcha/`, {captcha_code: captcha})
}

// #endregion

// #region
//https://api-dev.shop-delivery.ru/int_api/v2/users/post_phone_code/

export const postCodePhone = (code: string) => {
  return axios.post(`${USERS_URL}post_phone_code/`, {phone_code: code})
}

// #endregion

// #region
//https://api-dev.shop-delivery.ru/int_api/v2/users/post_phone_code/

export const getYMAccountsByToken = (tokenId: number) => {
  return axios.get(`${TOKENS_URL}get_ym_accounts_by_token/?token=${tokenId}`)
}

// #endregion

const ozonCookieApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCodePhoneFromOzon: builder.mutation<IApiResponseWithResult<number>, IGetOzonCodeRequest>({
      query: (body) => ({
        url: `${TOKENS_PREFIX}authorize_ozon_account/`,
        method: 'POST',
        body,
      }),
    }),
    postCodePhoneFromOzon: builder.mutation<void, IPostOzonCodeRequest>({
      query: (body) => ({
        url: `${TOKENS_PREFIX}confirm_ozon_account_sms_code/`,
        method: 'POST',
        body,
      }),
    }),
    postEmailCodeFromOzon: builder.mutation<void, IPostOzonCodeRequest>({
      query: (body) => ({
        url: `${TOKENS_PREFIX}confirm_ozon_account_email_code/`,
        method: 'POST',
        body,
      }),
    }),
  }),
  overrideExisting: false,
})

const yandexMarketApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    registerYMToken: builder.query<
      IApiResponseWithResult<IRegisterYMTokenResponse>,
      TRegisterYMTokenRequest
    >({
      query: (params) => ({
        url: `${TOKENS_URL}${params.id}/registrate_ym_token/`,
        params: {
          auth_key: params.validation_code,
        },
      }),
    }),
  }),
})

export const {
  useGetCodePhoneFromOzonMutation,
  usePostCodePhoneFromOzonMutation,
  usePostEmailCodeFromOzonMutation,
} = ozonCookieApiSlice

export const {useLazyRegisterYMTokenQuery} = yandexMarketApiSlice
