import {
  CardDataAddin,
  IAddinInAddin,
  IGoodCardOzonSaveAttribute,
  IGoodFieldAttribute,
} from '../../store/goods/api'
import {IGood} from '../../store/goods/types'
import {IImage} from '../../store/images/types'
import {IGoodFormFilter} from './item/GoodForm'
import {IUrlParameters} from '../../components/lists/UrlFilteredList'

export const DEFAULT_GOOD_ITEM_ID = -1

export interface IPaginationLink {
  url: string
  title: string
  num: number
}

export type TEnumGoodsQuickMenuItem =
  | 'List'
  | 'NewItem'
  | 'Item'
  | 'Import'
  | 'MpCards'
  | 'Groups'
  | 'ComparisonCategory'
  | 'ComparisonCards'

export const EnumGoodsQuickMenuItem = {
  List: 'List' as TEnumGoodsQuickMenuItem,
  NewItem: 'NewItem' as TEnumGoodsQuickMenuItem,
  Item: 'Item' as TEnumGoodsQuickMenuItem,
  Import: 'Import' as TEnumGoodsQuickMenuItem,
  MpCards: 'MpCards' as TEnumGoodsQuickMenuItem,
  Groups: 'Groups' as TEnumGoodsQuickMenuItem,
  ComparisonCategory: 'ComparisonCategory' as TEnumGoodsQuickMenuItem,
  ComparisonCards: 'ComparisonCards' as TEnumGoodsQuickMenuItem,
}

export interface IGoodsTableProps {
  title?: string
  description?: string
  className: string
  childrenContainerClassName?: string
  items?: IGood[]
  count: number
  isLoading?: boolean

  filterParams: IUrlParameters
  getPage?: () => {}
}

export interface IGoodTradeOfferTableProps {
  title?: string
  description?: string
  className?: string
  items?: IGoodTradeOffer[] | [] | null
  notSavedChanges?: boolean
  originalFieldName: string
  editFieldName: string
  filter?: IGoodFormFilter | null // filter field showing
  childrenContainerClassName?: string

  isInMpCards?: boolean // flag to mark if component renders in mpcards list (need for another logic than in good)

  deleteItemHandler: any // raises on delete item
}

let GoodOfferStatus: 'failed' | 'processing' | 'creating' | 'finished' | null | undefined
export type TGoodOfferStatus = typeof GoodOfferStatus

export interface IGoodMPCard {
  id: number
  sku: number[] | [] | null
  good: number | null
  marketplace: number | null // marketplace id
  company: number | null // company id
  params?: {
    status?: TGoodOfferStatus //"failed" | "processing" |"finished" | null // "finished",
    task_id?: string | null //"448702830",
    product_id?: string | null // 256176073,
    need_update?: boolean | null //false,
    need_to_update?: boolean | null //false
    unlinked_items?: number | null
  } | null
  created: Date | string | null
  account_id: number | null
  account_name: string | null
  price: IGoodCardPrice | null
  url_on_marketplace?: string
  is_published?: boolean | null // true - published, false - unpublished, if null - mpcard can't publish/unpublish

  // === custom properties
  isPublishing?: boolean // when publishing sets to true  (this is local property, not on server)
}

export interface IGoodCardPrice {
  comment?: string | null
  company?: string | null
  marketplace?: string | null
  params?: any | null
  posted?: boolean | null
  price?: number | null
}

export interface IGoodTradeOfferResponse {
  results: IGoodTradeOffer[] | [] | null
  count: number | null
}

export interface IGoodTradeOfferStock {
  id: number // id of stock
  warehouse: number //
  sku_customer_uid: string //"15895-2000000153759",
  quantity: number //3,
  reserved: number // 14,
  customer_company: number | null // null
}

export interface IGoodTradeOffer {
  id: number
  good: number // good id
  name: string // TO name
  customer_uid: string // article
  barcodes: string[] | []
  stock: IGoodTradeOfferStock[] | [] | null
  images: IImage[] | []
  mpcards: IGoodMPCard[] | []
  params: IGoodTradeOfferParams
}

export const emptyGoodTradeOffer: IGoodTradeOffer = {
  id: -1,
  good: -1,
  name: '',
  customer_uid: '',
  barcodes: [],
  stock: [],
  images: [],
  mpcards: [],
  params: {card_data: null, uploaded: null},
}

export interface IGoodTradeOfferSave {
  id: number
  good: string // good id
  name: string // TO name
  customer_uid: string // article
  barcodes: string[] | []
  stock: IGoodTradeOfferStock[] | [] | null
  image: number[] | []
  //mpcards: IGoodMPCard[]
  params: IGoodTradeOfferParams
}

export interface IGoodTradeOfferParams {
  card_data: IGoodTradeOfferCardData | null
  uploaded: any
}

export interface IGoodTradeOfferCardData {
  ozon?: {
    attributes?: IGoodCardOzonSaveAttribute[] | [] | null
    selectAttributes?: IGoodFieldAttribute[] | [] | null
  } | null
  wb?: {
    nomenclature?: {
      addin?: CardDataAddin[] | [] | null
      variations?: IAddinInAddin[]
    } | null
  } | null
}

// количество кнопок для разных разрешений экрана
export const DEFAULT_BUTTONS_PAGINATION = {
  xl: 5,
  lg: 5,
  md: 4,
  sm: 4,
  xs: 4,
}
