import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'
import {apiSlice} from '../api/apiSlice'

import * as auth from '../../app/modules/auth'
import * as storage from '../../app/store/index'

export interface StorageType {
  auth: any
  accounts: any
  deliveryAccounts: any
  dashboard: any
  marketplaces: any
  deliveryServices: any
  competitors: any
  customerCompanys: any
  customers: any
  categories: any
  goods: any
  marketing: any
  images: any
  orders: any
  orderStatuses: any
  prices: any
  system: any
  stocks: any
  warehouses: any
  mpcards: any
  mpdocs: any
  news: any
  users: any
  tasks: any
  priceTypes: any
  regions: any
  cdekPvz: any
  goodsGroups: any
  acceptanceRates: any
  feedback: any
  transactions: any
  subscriptions: any
  invoices: any
  transactionStatus: any
  mpWizard: any
  tgConnectModal: any
}

const storageObj: StorageType = {
  auth: auth.reducer,
  accounts: storage.accountReducer,
  deliveryAccounts: storage.deliveryAccountReducer,
  dashboard: storage.dashboardReducer,
  marketplaces: storage.marketplaceReducer,
  deliveryServices: storage.deliveryServiceReducer,
  customerCompanys: storage.customerCompanyReducer,
  customers: storage.customerReducer,
  categories: storage.categoryReducer,
  goods: storage.goodReducer,
  marketing: storage.marketingReducer,
  images: storage.imageReducer,
  system: storage.systemReducer,
  competitors: storage.competitorReducer,
  stocks: storage.stockReducer,
  orders: storage.orderReducer,
  prices: storage.priceReducer,
  orderStatuses: storage.orderStatusReducer,
  warehouses: storage.warehouseReducer,
  mpcards: storage.mpcardReducer,
  mpdocs: storage.mpdocReducer,
  users: storage.userReducer,
  tasks: storage.taskReducer,
  priceTypes: storage.priceTypesReducer,
  regions: storage.regionsReducer,
  cdekPvz: storage.cdekPvzReducer,
  goodsGroups: storage.goodsGroupsReducer,
  feedback: storage.feedbackReducer,
  acceptanceRates: storage.acceptanceRatesReducer,
  transactions: storage.transactionsReducer,
  invoices: storage.invoicesReducer,
  subscriptions: storage.subscriptionsReducer,
  transactionStatus: storage.transactionStatusReducer,
  mpWizard: storage.mpWizardReducer,
  tgConnectModal: storage.tgConnectModalReducer,
  news: storage.newsReducer,
}

export const rootReducer = combineReducers({
  ...storageObj,
  auth: auth.reducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([
    auth.saga(),
    storage.accountSaga(),
    storage.deliveryAccountSaga(),
    storage.dashboardSaga(),
    storage.marketplaceSaga(),
    storage.deliveryServiceSaga(),
    storage.customerCompanySaga(),
    storage.customerSaga(),
    storage.categorySaga(),
    storage.marketingSaga(),
    storage.goodSaga(),
    storage.imageSaga(),
    storage.systemSaga(),
    storage.competitorSaga(),
    storage.stockSaga(),
    storage.priceSaga(),
    storage.orderSaga(),
    storage.orderStatusSaga(),
    storage.warehouseSaga(),
    storage.priceTypesSaga(),
    storage.mpcardSaga(),
    storage.mpdocSaga(),
    storage.userSaga(),
    storage.taskSaga(),
    storage.regionsSaga(),
    storage.cdekPvzSaga(),
    storage.goodsGroupsSaga(),
    storage.feedbackSaga(),
    storage.acceptanceRatesSaga(),
    storage.transactionsSaga(),
    storage.subscriptionsSaga(),
    storage.invoicesSaga(),
    storage.checkTransactionStatusSaga(),
    storage.mpWizardSaga(),
    storage.thModalSaga(),
    storage.newsSaga(),
  ])
}
