import {ITemplate} from '../../utils/templateWorker'
import {IGoodFieldAttributeType} from '../goods/api'
import {
  IGoodMpCardUniversalValue,
  IGoodMpCardUniversalVariant,
  IMpCardUniversalAttributeValue,
} from '../mpcards/types'

export const initialMarketplaces: IMarketplaceState = {
  count: 0,
  next: '',
  previous: '',
  items: [],
}

export type TEnumMarketPlaceCode = 'ozon' | 'wb' | 'yandex' | 'avito' | 'sbmm' | 'ali' | 'cdek' | ''

export const EnumMarketPlaceCode = {
  OZON: 'ozon' as TEnumMarketPlaceCode,
  WB: 'wb' as TEnumMarketPlaceCode,
  YM: 'yandex' as TEnumMarketPlaceCode,
  AVITO: 'avito' as TEnumMarketPlaceCode,
  SBER: 'sbmm' as TEnumMarketPlaceCode,
  ALIEXPRESS: 'ali' as TEnumMarketPlaceCode,
  SDEK: 'cdek' as TEnumMarketPlaceCode,
  DEFAULT: '' as TEnumMarketPlaceCode,
}

export interface IMarketplace {
  id: number
  name: string
  params?: {
    pic?: string
    settings?: any
    // settings_v2?: IDynamicFieldType[]
    price_settings?: any
    features?: {
      cards_editor?: boolean
      attributes?: boolean
      cards_clone?: boolean
    }
  }
  settings_v2?: IUniversalDynamicFieldType[]
  code: TEnumMarketPlaceCode
}

export const emptyMarketplace: IMarketplace = {
  id: -1,
  name: '',
  code: EnumMarketPlaceCode.DEFAULT,
}

export interface IDynamicFieldType {
  description: string
  is_required: boolean
  is_colection: boolean
  name: string
  key: string
  type: IGoodFieldAttributeType
  options_url: string | null
}

export interface ITestField {
  parameterName: string
  value: string
}

export interface ITestFieldsToSend {
  marketplaceId: number
  connectionParams: ITestField[]
}

export interface IUniversalDynamicFieldType {
  id: number | null
  name: string
  description: string
  value: IGoodMpCardUniversalValue[]
  dict_link: string //link to dictioanary values (select options). add parameter  '?query=search phrase' to filter dictionary values
  only_dict: boolean // if true (default), there values only from dictionary (false - you can add your own values)
  value_type: IGoodFieldAttributeType
  allow_multiply: boolean // allow multiple value
  variants?: IGoodMpCardUniversalVariant[] | null
  choices?: IMpCardUniversalAttributeValue | null
  required: boolean //is required field
  read_only: boolean // is readonly field. can't edit it
  hidden: boolean // do not show field
  complex: boolean // if true, then value { value and count will be required}
  sub_tag?: string | null
  tag: string
  address: string
  code: string
  is_for_connection_test?: boolean | null
  request_param_name?: string | null
  // fieldNamesToSelectBefore?: string[] | null  // local property. needs to select fields with these addresses before disables it

  // address_value?: string  // result string for address when using template
  // direct_link_value?: string   // result string for direct_link when using template

  templates?: ITemplate[] | null // template values (system prop)
}

export const emptyUniversalDynamicFieldType: IUniversalDynamicFieldType = {
  address: '',
  allow_multiply: false,
  code: '',
  complex: false,
  description: '',
  dict_link: '',
  hidden: false,
  id: -1,
  name: '',
  only_dict: true,
  read_only: false,
  required: false,
  tag: '',
  value: [],
  value_type: 'multiline',
}

export interface IMarketplaceState {
  count: number
  next: string
  previous: string
  items: IMarketplace[]
  //marketplaces: Array<IAccount> | null
}

export const marketplaceActionTypes = {
  GetMarketplaces: '[GetMarketplaces] Action',
  SetMarketplaces: '[SetMarketplaces] Action',
}
