import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import ReactSelect, {InputActionMeta} from 'react-select'
import {useDebounce} from '../../../../_metronic/helpers'
import {SEARCH_DEBOUNCE_TIMEOUT} from '../../../utils/constants'
import {ISelectOption} from './AsyncSelectInput'

const DEFAULT_NOT_FOUND_MESSAGE = 'items not found'
const DEFAULT_LOADING_MESSAGE = 'loading...'

export interface IMultiListBoxFilterProps {
  className?: string
  items: ISelectOption[]
  selectedItems: any
  setSelected?: (items: any) => void
  notFoundMessage?: string
  loadingMessage?: string
  placeholder?: string
  onFocus?: any
  onBlur?: any
  disabled?: boolean
  isSearchable?: boolean
  isClearable?: boolean
  isMulti?: boolean
  isLoading?: boolean
  onInputChange?: any
  readonly?: boolean

  getOptionLabel?: any
  getOptionValue?: any

  keyProp?: any
}

export interface SelectProp {
  value: number
  label: string
}

export interface ISelectChangeInputProps {
  value: string
  meta: InputActionMeta | null
}

export const DEFAULT_META: ISelectChangeInputProps = {
  value: '',
  meta: null,
}

const SelectInput: FC<IMultiListBoxFilterProps> = (props) => {
  const [init, setInit] = useState<boolean>(false)
  const intl = useIntl()

  const [options, setOptions] = useState<any>([])
  const [searchMeta, setSearchMeta] = useState<ISelectChangeInputProps>(DEFAULT_META)

  const debouncedSearchTerm = useDebounce(searchMeta.value, SEARCH_DEBOUNCE_TIMEOUT)

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchMeta !== undefined && searchMeta.value != '') {
      props.onInputChange(searchMeta.value, searchMeta.meta)
    }
  }, [debouncedSearchTerm])

  const onInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    setSearchMeta({value: newValue, meta: actionMeta})
  }

  useEffect(() => {
    setOptions(props.items)
  }, [props.items])

  // fires when change selected items
  const selectItems = (newValue: any) => {
    if (Array.isArray(newValue)) {
      props.setSelected &&
        props.setSelected(
          newValue.map((v) => {
            return {
              value: v?.value ?? 0,
              label: v?.label ?? '',
            }
          })
        )
    } else {
      props.setSelected &&
        props.setSelected({
          value: newValue?.value ?? 0,
          label: newValue?.label ?? '',
        })
    }
  }

  useEffect(() => {
    setInit(true)
  }, [])

  return props.keyProp ? (
    <ReactSelect
      className={props.className ? props.className : ''}
      key={props.keyProp}
      isOptionDisabled={props.disabled ? () => true : undefined}
      isDisabled={props.readonly}
      isClearable={Boolean(props.isClearable)}
      options={options}
      placeholder={props.placeholder}
      loadingMessage={() => <span>{props.loadingMessage ?? DEFAULT_LOADING_MESSAGE}</span>}
      noOptionsMessage={() => <span>{props.notFoundMessage ?? DEFAULT_NOT_FOUND_MESSAGE}</span>}
      isLoading={props.isLoading ?? false}
      onChange={selectItems}
      getOptionLabel={props.getOptionLabel}
      getOptionValue={props.getOptionValue}
      isSearchable={props.isSearchable ?? false}
      isMulti={props.isMulti ?? false}
      value={props.selectedItems}
      onInputChange={onInputChange}
      onBlur={props.onBlur && props.onBlur}
      onFocus={props.onFocus && props.onFocus}
    />
  ) : (
    <ReactSelect
      className={props.className ? props.className : ''}
      isDisabled={props.readonly}
      isOptionDisabled={props.disabled ? () => true : undefined}
      isClearable={Boolean(props.isClearable)}
      options={options}
      placeholder={props.placeholder}
      loadingMessage={() => <span>{props.loadingMessage ?? DEFAULT_LOADING_MESSAGE}</span>}
      noOptionsMessage={() => <span>{props.notFoundMessage ?? DEFAULT_NOT_FOUND_MESSAGE}</span>}
      isLoading={props.isLoading ?? false}
      onChange={selectItems}
      getOptionLabel={props.getOptionLabel}
      getOptionValue={props.getOptionValue}
      isSearchable={props.isSearchable ?? false}
      isMulti={props.isMulti ?? false}
      value={props.selectedItems}
      onInputChange={onInputChange}
      onBlur={props.onBlur && props.onBlur}
      onFocus={props.onFocus && props.onFocus}
    />
  )
}

export default SelectInput
