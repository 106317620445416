import {createAction} from '@reduxjs/toolkit'
import {IMonitoring, INotify, IRules, EnumWarehouseRatesActionTypes} from './types'

export const FETCH_ACCEPTANCE_RATES = EnumWarehouseRatesActionTypes.FetchWarehouseRates

export const FETCH_ACCEPTANCE_RATES_BY_ID = EnumWarehouseRatesActionTypes.FetchWarehouseRatesById

export const UPDATE_ASYNC_ACCEPTANCE_RATES_BY_ID =
  EnumWarehouseRatesActionTypes.UpdateWarehouseRatesById

export const UPDATE_ASYNC_ACCEPTANCE_RATES_WAREHOUSE_BY_ID =
  EnumWarehouseRatesActionTypes.UpdateWarehouseRatesWarehousesById

export const DELETE_ASYNC_ACCEPTANCE_RATES_BY_ID =
  EnumWarehouseRatesActionTypes.DeleteWarehouseRatesId

export const UPDATE_ASYNC_ACCEPTANCE_RATES_NOTIFICATION_BY_ID =
  EnumWarehouseRatesActionTypes.UpdateWarehouseRatesNotificationById

export const UPDATE_ASYNC_RULES_BY_ID = EnumWarehouseRatesActionTypes.UpdateRulesById

// Actions

export const fetchWarehouseRatesAction = createAction(FETCH_ACCEPTANCE_RATES)

export const fetchWarehouseRatesByIdAction = createAction<number>(FETCH_ACCEPTANCE_RATES_BY_ID)

export const updateWarehouseRatesByIdAction = createAction<IMonitoring>(
  UPDATE_ASYNC_ACCEPTANCE_RATES_BY_ID
)

export const updateAcceptanceRatesWarehouseByIdAction = createAction<number>(
  UPDATE_ASYNC_ACCEPTANCE_RATES_WAREHOUSE_BY_ID
)

export const deleteAsyncWarehouseRatesByIdAction = createAction<number>(
  DELETE_ASYNC_ACCEPTANCE_RATES_BY_ID
)

export const updateAcceptanceRatesNotificationByIdAction = createAction<INotify>(
  UPDATE_ASYNC_ACCEPTANCE_RATES_NOTIFICATION_BY_ID
)

export const updateRulesByIdAction = createAction<IRules>(UPDATE_ASYNC_RULES_BY_ID)
