import {IUrlParameters} from '../../components/lists/UrlFilteredList'
import {TCheckThreeState} from '../goodsGroups/types'
import {IWarehouseResponse} from '../warehouses/types'

const DEFAULT_LIMIT = 5

type notify = {
  notify: boolean
}
export interface INotify {
  id: number
  notify: notify
}

export interface IRules {
  id: number
  rules: IWarehouseRule[]
}

const emptyUrlItems: IUrlParameters = {
  filter: '',
  itemsPerPage: DEFAULT_LIMIT,
  ordering: '',
  pageNumber: 0,
  url: '',
}
export const emptyWarehouseRatesUrlParameters: IUrlParameters = {
  ...emptyUrlItems,
  url: '',
}

export interface WarehouseItemParams {
  color: string
  coefficient: number
  notify: boolean
}

export interface WarehouseDayItem {
  box: WarehouseItemParams
  date: string
  pallet: WarehouseItemParams
  supersafe: WarehouseItemParams
}

export interface IWarehouseItem {
  days: WarehouseDayItem[]
  wh_name: string | null
}

export const emptyWarehouseItem: IWarehouseItem = {
  days: [],
  wh_name: null,
}

export interface IRulesAccount {
  id: string
  name: string
  wb_lk_supplier_id: string
}

export interface ISupply {
  account?: IRulesAccount
  boxTypeName?: string
  supplyId: number
  message: string
  warehouses?: {
    warehouseId: number
    warehouseName: string
  }
}
export interface IWarehouseRule {
  id: string
  warehouse: IWarehouseResponse
  account: IRulesAccount
  type_name: string | null
  supply_id: ISupply
  max_rate: number | null
  rate: number | null
  supply_date: string
  planing_supply_dates: string[]
  check_profitable_coef: boolean
  set_on_wb: boolean
  status: string
}

export const emptyWarehouseRule: IWarehouseRule = {
  id: '',
  warehouse: {id: '', label: ''},
  account: {id: '', name: '', wb_lk_supplier_id: ''},
  type_name: '',
  supply_id: {message: '', supplyId: -1},
  planing_supply_dates: [],
  check_profitable_coef: false,
  rate: null,
  max_rate: null,
  supply_date: '',
  set_on_wb: false,
  status: '',
}

export const emptyWarehouseRules: IWarehouseRule[] = [
  {
    id: '',
    warehouse: {id: '', label: ''},
    account: {id: '', name: '', wb_lk_supplier_id: ''},
    type_name: '',
    rate: null,
    planing_supply_dates: [],
    check_profitable_coef: false,
    supply_id: {message: '', supplyId: -1},
    max_rate: null,
    supply_date: '',
    set_on_wb: false,
    status: '',
  },
]

export interface MonitoringItem {
  id: number
  name: string
  max_coefficient_box: number
  max_coefficient_monopallet: number
  max_coefficient_supersafe: number
  created_at: string
  updated_at: string
  warehouses: IWarehouseResponse[]
  result: IWarehouseItem[]
  rules: IWarehouseRule[]
  is_active: boolean
  notify: boolean
  author?: number
}

export interface IMonitoring {
  id: number
  name: string
  max_coefficient_box: number
  max_coefficient_monopallet: number
  max_coefficient_supersafe: number
  warehouses: IWarehouseResponse[]
  created_at?: string
  updated_at?: string
  selected?: TCheckThreeState
  notify?: boolean
  author?: number
  rules?: IWarehouseRule[] | {}
}

export const emptyMonitoring: IMonitoring = {
  id: 0,
  name: '',
  max_coefficient_box: 0,
  max_coefficient_monopallet: 0,
  max_coefficient_supersafe: 0,
  warehouses: [],
  created_at: '',
  updated_at: '',
  selected: 'unchecked',
  notify: true,
  rules: {},
}

export interface ISelectedRule {
  action: string
  rule: IWarehouseRule
  monitoringItem: MonitoringItem
}

export interface IWarehouseRatesStorage {
  isLoading: boolean
  isLoadingWarehouses?: boolean
  isLoadingRules?: boolean
  items: MonitoringItem[]
  rules: IWarehouseRule[]
  selected: MonitoringItem | null
  selectedRule: ISelectedRule | null
  count: number
  next: string | null
  previous: string | null
  filters: IUrlParameters
  notify: boolean
  isShowAddAccountModal: boolean
}

export const emptyState: IWarehouseRatesStorage = {
  isLoading: false,
  isLoadingWarehouses: false,
  isLoadingRules: false,
  rules: emptyWarehouseRules,
  items: [],
  selected: null,
  selectedRule: null,
  count: 0,
  next: null,
  previous: null,
  filters: emptyWarehouseRatesUrlParameters,
  notify: false,
  isShowAddAccountModal: false,
}

export interface IWBAccountAddForm {
  company: number
  accountName: string
  authToken: string
  vendorCode: string
  validationСode: string
}

export const emptyWBAccountAddForm: IWBAccountAddForm = {
  company: 0,
  accountName: '',
  authToken: '',
  vendorCode: '',
  validationСode: '',
}

export type TWarehouseRatesActionTypes =
  | 'FetchWarehouseRates'
  | 'FetchWarehouseRatesById'
  | 'UpdateWarehouseRatesById'
  | 'UpdateWarehouseRatesWarehousesById'
  | 'DeleteWarehouseRatesId'
  | 'UpdateWarehouseRatesNotificationById'
  | 'UpdateRulesById'

export const EnumWarehouseRatesActionTypes = {
  FetchWarehouseRates: 'FetchWarehouseRates' as TWarehouseRatesActionTypes,
  FetchWarehouseRatesById: 'FetchWarehouseRatesById' as TWarehouseRatesActionTypes,
  UpdateWarehouseRatesById: 'UpdateWarehouseRatesById' as TWarehouseRatesActionTypes,
  UpdateWarehouseRatesWarehousesById:
    'UpdateWarehouseRatesWarehousesById' as TWarehouseRatesActionTypes,
  DeleteWarehouseRatesId: 'DeleteWarehouseRatesId' as TWarehouseRatesActionTypes,
  UpdateWarehouseRatesNotificationById:
    'UpdateWarehouseRatesNotificationById' as TWarehouseRatesActionTypes,
  UpdateRulesById: 'UpdateRulesById' as TWarehouseRatesActionTypes,
}
