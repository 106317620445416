import {createAction} from '@reduxjs/toolkit'
import {EnumFetchFeedbacks, IFeedbackRequestParams, IFeedbackSendParams} from './types'

export const FETCH_FEEDBACKS = EnumFetchFeedbacks.FetchFeedbacks
export const FETCH_FETCH_FEEDBACKS_BY_ID = EnumFetchFeedbacks.FetchFeedbackById
export const ASYNC_GENERATE_FEEDBACK = EnumFetchFeedbacks.SET_Feedback

export const ASYNC_SEND_FEEDBACK = EnumFetchFeedbacks.SEND_Feedback
export const FETCH_SETTINGS = EnumFetchFeedbacks.FetchSettings

export const fetchFeedbacksAction = createAction(FETCH_FEEDBACKS)

export const fetchFeedbackByIdAction = createAction<number>(FETCH_FETCH_FEEDBACKS_BY_ID)

export const generateFeedbackCreator = createAction<IFeedbackRequestParams>(ASYNC_GENERATE_FEEDBACK)

export const sendFeedbackAction = createAction<IFeedbackSendParams>(ASYNC_SEND_FEEDBACK)

export const fetchFeedbacksSettingsAction = createAction(FETCH_SETTINGS)
