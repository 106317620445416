import {useState, ChangeEvent} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getYMAccountsByTokenAction} from '../../../../../store/mpWizard/actions'
import {useLazyRegisterYMTokenQuery} from '../../../../../store/mpWizard/api'
import {updateData, setSelectedToken, nextStep} from '../../../../../store'

import {Form, Button} from 'react-bootstrap'
import TokenSelect from '../../TokenSelect'

import {StorageType} from '../../../../../../setup'
import {ITokenFilters} from '../../../../../store/tokens/types'
import {IUserModel} from '../../../../auth/models/UserModel'
import {IWizardState} from '../../../../../store/mpWizard/types'

const YMStep1 = () => {
  const [ymCode, setYmCode] = useState('')

  const user: IUserModel = useSelector((state: StorageType) => state.auth.user)

  const {marketplace, selectedToken, currentStep}: IWizardState = useSelector(
    (storage: StorageType) => storage.mpWizard
  )

  const [registerYMToken, {isLoading, isFetching}] = useLazyRegisterYMTokenQuery()

  const isRegisterLoading = isLoading || isFetching

  const tokenFilters: ITokenFilters = {
    customer: user.customer.id.toString(),
    marketplace: [{label: '', value: marketplace?.id.toString() || ''}],
  }

  const dispatch = useDispatch()

  const onSendCode = async () => {
    const response = await registerYMToken({id: user.id, validation_code: ymCode}).unwrap()

    const result = response.result

    if (result) {
      dispatch(setSelectedToken({label: '', value: result.token.toString()}))
      dispatch(
        updateData({key: `step${currentStep}`, field: 'ymAccounts', value: result.campaigns})
      )
      dispatch(nextStep())
    }
  }

  const onChangeCode = (e: ChangeEvent<HTMLInputElement>) => {
    setYmCode(e.target.value)
  }

  const onSendToken = () => {
    if (selectedToken) {
      dispatch(getYMAccountsByTokenAction(+selectedToken.value))
    }
  }

  return (
    <div className='fs-5'>
      <p className='mb-8'>
        Для авторизации в аккаунте Яндекс Маркет, пожалуйста, нажмите на кнопку ниже. Это действие
        откроет страницу Яндекс Маркета, где вам нужно будет войти в свой аккаунт, используя ваши
        учетные данные. После успешной авторизации вам необходимо создать токен и ввести его в поле
        ниже.
      </p>

      <h4 className='mb-3 fs-4'>Как создать токен</h4>
      <ol className='mb-8'>
        <li>
          Перейдите в{' '}
          <a target='_blank' rel='noreferrer' href='https://partner.market.yandex.ru/supplier/'>
            личный кабинет.
          </a>
        </li>
        <li>
          В кабинете в левом нижнем углу наведите на название своего бизнеса и перейдите на страницу{' '}
          <b>Модули и АРІ.</b>
        </li>
        <li>
          В блоке <b>Авторизационный токен</b> нажмите кнопку <b>Создать</b> или{' '}
          <b>Создать новый токен</b>, если вы уже получали его.
        </li>
        <li>
          В открывшемся окне:
          <ul>
            <li>Укажите уникальное название токена.</li>
            <li>Выберите доступы для этого токена — один или несколько.</li>
            <li>
              Нажмите кнопку <b>Создать.</b>
            </li>
          </ul>
        </li>
      </ol>
      <Form.Control
        name='authorization'
        value={ymCode}
        disabled={Boolean(selectedToken)}
        onChange={onChangeCode}
        className='bg-secondary mb-9'
        placeholder='Введите токен'
      />
      <Button disabled={isRegisterLoading || !ymCode} className='fs-6 mb-5' onClick={onSendCode}>
        Отправить токен
      </Button>

      <p className='fs-5 text-gray-700'>Или выберите токен из списка ниже</p>
      <TokenSelect className='w-25' filters={tokenFilters} disabled={Boolean(ymCode)} />

      {selectedToken && (
        <Button className='mt-5' onClick={onSendToken} disabled={isRegisterLoading}>
          Далее
        </Button>
      )}
    </div>
  )
}

export default YMStep1
