import {FC, ChangeEvent} from 'react'
import {Form} from 'react-bootstrap'

interface ICodeInputProps {
  value: string
  onChangeInput: (event: ChangeEvent<HTMLInputElement>) => void
  disabled: boolean
  placeholder: string
  name: string
}

const CodeInput: FC<ICodeInputProps> = (props) => {
  const {value, onChangeInput, disabled, placeholder, name} = props

  return (
    <div>
      <Form.Control
        name={name}
        disabled={disabled}
        value={value}
        onChange={onChangeInput}
        className='bg-secondary mb-6 w-50'
        placeholder={placeholder}
      />
    </div>
  )
}

export default CodeInput
