import {
  createApi,
  fetchBaseQuery,
  FetchArgs,
  BaseQueryFn,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import {actions, IAuthState} from '../../app/modules/auth'
import {StorageType} from '../redux/RootReducer'
import {REFRESH_TOKEN_URL} from '../../app/store/auth/AuthCRUD'

interface IMetaData {
  successMessage: string
}

export interface ITransformedResponse<T> {
  data: T
  meta: IMetaData
}

export interface IApiResponseWithResult<T> {
  success: boolean
  count?: number
  results?: T[]
  result?: T
}

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`,
  prepareHeaders: (headers, {getState, endpoint}) => {
    headers.set('Content-Type', 'application/json')

    if (endpoint !== 'refresh') {
      const access = (getState() as StorageType).auth.access

      if (access) {
        headers.set('Authorization', `JWT ${access}`)
      }
    }

    return headers
  },
})

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  let result = await baseQuery(args, api, extraOptions)

  if (result.error?.status === 401) {
    const refresh = (api.getState() as StorageType).auth.refresh

    const refreshResult = await baseQuery(
      {
        url: REFRESH_TOKEN_URL,
        method: 'POST',
        body: {
          refresh,
        },
      },
      {...api, endpoint: 'refresh'},
      extraOptions
    )

    if (refreshResult?.data) {
      api.dispatch(actions.setAccessToken(refreshResult.data as IAuthState))

      result = await baseQuery(args, api, extraOptions)
    } else {
      api.dispatch(actions.logout())
    }
  }

  return result
}

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  tagTypes: [
    'ProcessingRules',
    'ExtraReplyRules',
    'ProcessingTemplates',
    'ProductRecomendation',
    'TemplatesTags',
    'Tokens',
  ],
})
