import {ICustomer} from '../../modules/auth/models/UserModel'

export type TEnumSubscriptionType = 'new' | 'pro'

export const EnumSubscriptionType = {
  NEW: 'new' as TEnumSubscriptionType,
  PRO: 'pro' as TEnumSubscriptionType,
}

export type TEnumSubscriptionCategory = 'favorable' | 'service' | 'subscription'

export const EnumSubscriptionCategory = {
  FAVORABLE: 'favorable' as TEnumSubscriptionCategory,
  SERVICE: 'service' as TEnumSubscriptionCategory,
  SUBSCRIPTION: 'subscription' as TEnumSubscriptionCategory,
}

export type TEnumPaymentMethod = 'card' | 'balance' | 'invoice'

export const EnumPaymentMethod = {
  CARD: 'card' as TEnumPaymentMethod,
  BALANCE: 'balance' as TEnumPaymentMethod,
  INVOICE: 'invoice' as TEnumPaymentMethod,
}

export const emptySubscriptionsState: ISubscriptionsState = {
  userSubscriptions: [],
  subscriptions: {
    [EnumSubscriptionCategory.FAVORABLE]: [],
    [EnumSubscriptionCategory.SERVICE]: [],
    [EnumSubscriptionCategory.SUBSCRIPTION]: [],
  },
  selectedTariff: {
    duration: 1,
    id: 1,
  },
  tariffs: [
    {
      duration: 1,
      id: 1,
    },
    {
      duration: 3,
      id: 2,
    },
    {
      duration: 6,
      id: 3,
    },
  ],
  redirectLink: '',
  invoiceDocument: '',
  selectedSubscriptions: [],
  isCreatingPayment: false,
  isLoading: false,
  isTopupBalanceLoading: false,
  isUserSubscriptionsLoading: false,
  isTariffChanging: false,
  isRenewing: false,
  successCreatignPayment: false,
}

export interface ISubscriptionsState {
  subscriptions: CategorizedSubscriptionsType
  userSubscriptions: IUserSubscription[]
  selectedSubscriptions: ISubscription[]
  tariffs: ITarif[]
  redirectLink: string
  invoiceDocument: string
  selectedTariff: ITarif
  isCreatingPayment: boolean
  isTopupBalanceLoading: boolean
  isLoading: boolean
  isUserSubscriptionsLoading: boolean
  isTariffChanging: boolean
  isRenewing: boolean
  successCreatignPayment: boolean
}

export interface ITarif {
  duration: number
  id: number
}

interface ISubscriptionTarif {
  code: string
  duration: number
  old_price: number
  price: number
}

export interface IUserSubscription {
  id: number
  customer: number
  active: boolean
  start_date: string
  end_date: string
  auto_renewal: boolean
  code_plan: string
  plan: ISubscription
}

export interface ISubscription {
  id: number
  name: string
  description: string
  tariffs: ISubscriptionTarif[]
  category: ISubscriptionCategory
  type: TEnumSubscriptionType
  code: string
  auto_renewal: boolean
}

export interface IPaymentRequestParams {
  subscriptions: Array<{id: number; code: string; renewal: boolean}>
  amount: number
}

export interface IRenewSubByInvoiceRequestParams {
  subscription_id: number
  payer?: number
}

export interface IBuySubscriptionRequestParams {
  subscriptions: Array<{id: number; code: string; renewal: boolean}>
  updatedCustomerInfo: ICustomer
}

export interface IChangeTariffRequestParams {
  tariff_code: string
  subscription_id: number
  updatedCustomerInfo: ICustomer
}

export type CategorizedSubscriptionsType = {
  [key: string]: ISubscription[]
}

export interface ISubscriptionCategory {
  id: number
  name: string
  code: string
}

export interface ICheckTransactionStatusResponse {
  id: number
  customer: number
  amount: string
  issued_date: string
  due_date: string
}

export const subscriptionsActionTypes = {
  GET_SUBSCRIPTIONS: '[GetSubscriptions] Action',
  GET_USER_SUBSCRIPTIONS: '[GetUserSubscriptions] Action',
  AUTO_RENEW__SUBSCRIPTION: '[AutoRenewSubscription] Action',
  CREATE_PAYMENT: '[CreatePayment] Action',
  TOP_UP_BALANCE: '[TopUpBalance] Action',
  BUY_SUBSCRIPTION: '[BuySubscription] Action',
  CHANGE_TARIFF: '[ChangeTariff] Action',
  RENEW_SUBSCRIPTION_BY_CARD: '[renewSubscriptionByCard] Action',
  RENEW_SUBSCRIPTION_BY_INVOICE: '[renewSubscriptionByInvoice] Action',
  RENEW_SUBSCRIPTION_BY_BALANCE: '[renewSubscriptionByBalance] Action',
}
